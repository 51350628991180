import axios from "axios";
import { useEffect, useState } from "react";
import config from '../../../config.json';
import { RegistrationQuestionModel } from "../../../types/models";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { handlerApiErrors } from "../../../vendor/handlerApiErrors";

type FormValues = {
    [key: string]: string
}

export const RegisterFormQuestions = ({setRegisterToken}: {
    setRegisterToken: React.Dispatch<React.SetStateAction<string | null>>
}) => {

    const [questions, setQuestions] = useState<Array<RegistrationQuestionModel> | null>(null);

    const loadData = async () => {
        const response = await axios.get(`${config.mainURL}/register/questions`);
        if(response.status != 200) toast.error("Произошла ошибка при загрузке вопросов");
        else
        {
            setQuestions(response.data);
        }
    }
    useEffect(() => {
        loadData();
    }, [])

    const {
        register,
        setValue,
        formState: {errors},
        handleSubmit
    } = useForm();

    const createRegisterToken = async (data: FormValues) => {
        const keys = Object.keys(data);
        console.log('data', data);
        const response = await axios.post(`${config.mainURL}/register/create-register-token`, {
            items: keys.map(key => {
                const id = key.replace('quest', '');
                return {
                    answerId: data[key],
                    questionId: id
                };
            })
        });
        if(response.status != 201) 
        {
            toast.error(handlerApiErrors(response));
            
        }
        else
        {
            console.log('token', response.data.token);
            setRegisterToken(response.data.token);
        }
    }

    return (
        <form className="theme-form needs-validation" onSubmit={handleSubmit(createRegisterToken)}>
            {questions && questions.map((quest, i) => 
                <div className="form-group m-t-15 m-b-15 custom-radio-ml">
                    <p>{i+1}. {quest.text}</p>
                    {quest.answers.map(answer => 
                        <>
                            <div className="radio radio-primary p-0">
                                <input id={`ans_${answer.id}`} type="radio" {...register(`quest${quest.id}`, {required: true})} value={answer.id} />
                                <label style={{maxWidth: '350px'}} htmlFor={`ans_${answer.id}`}>{answer.text}</label>
                            </div>
                        </>
                    )}
                    <hr />
                </div>
            )}
            <div className="d-grid">
                <button className="btn btn-primary">Проверить</button>
            </div>
        </form>
    )
}