import { Link } from "react-router-dom";
import Breadcrumb from "../../components/common/breadcrumb"
import { useGetCharactersQuery } from "../../store/api/charactersApi"
import { useState } from "react";
import statusesCharacter from "../../vendor/strings/statusesCharacter";

export const CharactersListPage = () => {

    const {isLoading, isSuccess, isError, data: characters} = useGetCharactersQuery();
    const [questions, setQuestions] = useState(null);

    return (
        <>
            <Breadcrumb title="Персонажи"/>
            <div className="container-fluid">
                <div className="row">
                    {isSuccess &&
                    <>
                        {characters.map((character, i) => 
                            <div className="col-sm-4">
                                <div className="card">
                                    <div className="card-header text-center">
                                        {i+1} слот<br />
                                        <small className="txt-info">(Краткая информация)</small>
                                    </div>
                                    <div className="card-body">
                                        <ul className="list-group">
                                            <li className="d-flex justify-content-between list-group-item">
                                                Имя персонажа <span>{character.name}</span>
                                            </li>
                                            <li className="d-flex justify-content-between list-group-item">
                                                Cтатус <span>{statusesCharacter[character.status]}</span>
                                            </li>
                                            <li className="d-flex justify-content-between list-group-item">
                                                Всего сыграно <span>{character.gameHours}</span>
                                            </li>
                                            <li className="d-flex justify-content-between list-group-item">
                                                Создан <span>{character.createdAt}</span>
                                            </li>
                                        </ul>
                                        <br />
                                        <div className="d-grid">
                                            <Link to={`/characters/${character.id}`} className="btn btn-primary">Просмотр</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        )}
                        {Array(3 - characters.length).fill(0).map((_, i) => 
                            <div className="col-sm-4">
                                <div className="card">
                                    <div className="card-header text-center">
                                        Cвободный слот для персонажа<br />
                                        <small className="txt-info">(Краткая информация)</small>
                                    </div>
                                    <div className="card-body">
                                        <ul className="list-group">
                                            <li className="d-flex justify-content-between list-group-item">
                                                Имя персонажа <span>-</span>
                                            </li>
                                            <li className="d-flex justify-content-between list-group-item">
                                                Cтатус <span>-</span>
                                            </li>
                                            <li className="d-flex justify-content-between list-group-item">
                                                Всего сыграно <span>-</span>
                                            </li>
                                            <li className="d-flex justify-content-between list-group-item">
                                                Создан <span>-</span>
                                            </li>
                                        </ul>
                                        <br />
                                        <div className="d-grid">
                                            <Link to="/characters/create" className="btn btn-primary">Создать</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        )}
                    </>
                    }
                </div>
            </div>
        </>
    )
}