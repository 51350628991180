
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth'
import axios from 'axios';

export const AuthMiddleware = () => {
    const {user} = useAuth();
    const location = useLocation();
    return user
        ? <Outlet/>
        : <Navigate to="/auth" state={{from: location}} />
}