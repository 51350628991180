import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import man from '../../../assets/images/dashboard/user.png'
import { useAuth } from '../../../hooks/useAuth';
// import { Link } from 'react-router-dom';
// import { Edit } from 'react-feather';
import userAvatar from '../../../assets/images/user-logo.jpeg';

const UserPanel = () => {
    const { user } = useAuth();
    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    <img className="img-60 rounded-circle lazyloaded blur-up" src={userAvatar} alt="#" />
                    {/* <div className="profile-edit">
                        <Link to={`${process.env.PUBLIC_URL}/users/userEdit`}>
                            <Edit />
                        </Link>
                    </div> */}
                </div>
                <h6 className="mt-3 f-14"><Link to='/'>{user?.login}</Link></h6>
                {/* <p>Desc.</p> */}
            </div>
        </Fragment>
    );
};

export default UserPanel;