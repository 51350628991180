import { useState } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { CharacterModel } from "../../../types/models"

export type CharacterLoginDetailsModalProps = {
    character: CharacterModel<'get'>
}
export const CharacterLoginDetailsModal = ({character}: CharacterLoginDetailsModalProps) => {

    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(s => !s);

    return (
        <>
            <button onClick={toggle} className="btn btn-outline-primary">Информация для входа</button>
            <Modal fade={false} isOpen={open} toggle={toggle}>
                <ModalHeader toggle={toggle}>Информация для входа на сервере</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group form-row">
                                <label htmlFor="" className="col-form-label">Логин</label>
                                <input type="text" disabled className="form-control" defaultValue={character.authInfo?.login} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group form-row">
                                <label htmlFor="" className="col-form-label">Пароль</label>
                                <input type="text" disabled className="form-control" defaultValue={character.authInfo?.password} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}