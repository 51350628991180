import React, { useState, Fragment, useEffect, useCallback, useRef } from 'react';
import { MenuItem } from '../sidebar-component/menu';
import { Link } from 'react-router-dom';
import { HelpCircle, Icon, Key, Search, User, Users } from 'react-feather';
import { useAuth } from '../../../hooks/useAuth';
import config from '../../../config.json';
import { toast } from 'react-toastify';
import { handlerApiErrors } from '../../../vendor/handlerApiErrors';
import { MainMenuState } from '../../app';

export type SearchMenuItem = {
    title: string
    path: string | null | undefined
    icon: Icon
    search: string
}


const SearchHeader = ({mainmenu}: {mainmenu: MainMenuState}) => {
    

    
    const [searchValue, setsearchValue] = useState('');
    const [searchOpen, setsearchOpen] = useState(false);
    const [searchArray, setSearchArray] = useState<Array<SearchMenuItem> | null>(null);
    const [searchItems, setSearchItems] = useState<Array<SearchMenuItem> | null>(null);

    const escFunction = useCallback((event: KeyboardEvent) => {
        if (event.keyCode === 27) {
            //Do whatever when esc is pressed
            setsearchOpen(false)
            setsearchValue('')
        }
    }, []);
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        let newSearchItems: Array<SearchMenuItem> = [];
        for(const item of mainmenu.mainmenu)
        {
            if(!item.children)
            {
                newSearchItems.push({
                    title: `Раздел: ${item.title}`,
                    path: item.path,
                    icon: item.icon,
                    search: item.title.toLowerCase()
                })
            }
            else
            {
                for(const subItem of item.children)
                {
                    newSearchItems.push({
                        title: `Раздел: ${subItem.title}`,
                        path: subItem.path,
                        icon: item.icon,
                        search: subItem.title.toLowerCase()
                    })
                }
            }
        }
        setSearchItems(newSearchItems);
    }, [])
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    });

    const handleSearchKeyword = (keyword: string) => {
        keyword ? addFix() : removeFix()
        const items: SearchMenuItem[] = [];
        const regex = new RegExp(`^${keyword.toLowerCase()}`)
        if(searchItems)
        {
            for(const item of searchItems)
            {
                if(items.length >= 10) break;
                if(regex.test(item.search))
                    items.push(item);
            }
            checkSearchResultEmpty(items);
            setSearchArray((items.length > 0 ? items : null));
        }
    }

    const checkSearchResultEmpty = (items: SearchMenuItem[]) => {
        if (!items.length) {
            document.querySelector(".empty-menu")?.classList.add('is-open');
        } else {
            document.querySelector(".empty-menu")?.classList.remove('is-open');
        }
    }

    const addFix = () => {
        document.querySelector(".Typeahead-menu")?.classList.add('is-open');
        document.body.classList.add("offcanvas");
    }

    const removeFix = () => {
        if(inputRef.current) inputRef.current.value = '';
        document.querySelector(".Typeahead-menu")?.classList.remove('is-open');
        document.body.classList.remove("offcanvas");
    }

    const toggleBtn = () => {
        if (searchOpen) {
            setsearchOpen(!searchOpen);
            document.querySelector('.searchIcon')?.classList.add('open');
        } else {
            setsearchOpen(!searchOpen);
            document.querySelector('.searchIcon')?.classList.remove('open');
        }
    }


    return (
        <Fragment>
            <div>
                <form className="form-inline search-form">
                    <div className="form-group">
                        <input
                            className="form-control-plaintext searchIcon"
                            type="text"
                            placeholder="Поиск..."
                            defaultValue={searchValue}
                            ref={inputRef}
                            onChange={(e) => handleSearchKeyword(e.target.value)}
                        />
                        <span className="d-sm-none mobile-search" onClick={toggleBtn}>
                            <Search />
                        </span>

                        <div className="Typeahead-menu custom-scrollbar" id="search-outer">
                            {searchArray && searchArray.map((data, index) => {
                                return (
                                    <div className="ProfileCard u-cf" key={index}>
                                        <div className="ProfileCard-avatar">
                                            {data.icon && <data.icon />}
                                        </div>
                                        <div className="ProfileCard-details">
                                            <div className="ProfileCard-realName">
                                                <Link
                                                    to={data.path as string}
                                                    className="realname"
                                                    onClick={removeFix}
                                                >
                                                    {data.title}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="Typeahead-menu empty-menu">
                            <div className="tt-dataset tt-dataset-0">
                                <div className="EmptyMessage">
                                    Ничего не найдено!
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    );
};

export default SearchHeader;