import { useState } from "react"
import { User } from "react-feather"
import { Link } from "react-router-dom";
import { MainHeader } from "../components/common/header-component/main-header";

export const IndexPage = () => {

    return (
        <>
            <MainHeader/>
            <div className="row justify-content-center" style={{marginTop: '100px'}}>
                <div className="col-sm-6">
                    <h1 className="text-center">
                        <b>Troubled Time Role Play</b>
                    </h1>
                    <p className="text-center p-3">
                        Надоели однотипные ролевые проекты? Ищешь уникальный и новый опыт игры? Наш проект приглашает тебя окунуться в захватывающую ролевую игру в мире постапокалипсиса, наполненном множеством опасностей и вызовов. Присоединяйся к нам, чтобы испытать подлинное напряжение и глубину игрового процесса.
                    </p>
                    <br />
                    <div className="row justify-content-center">
                        <div className="col-sm-5 d-grid">
                            <Link to="/register" className="btn btn-outline-primary">Создать аккаунт</Link>
                        </div>
                        {/* <div className="col-sm-4"></div> */}
                        <div className="col-sm-5 d-grid">
                            <Link to="/auth" className="btn btn-outline-primary">Войти в аккаунт</Link>
                        </div>
                    </div>
                    <br />
                    <div className="row justify-content-center" style={{margin: '30px'}}>
                        <div className="col-sm-6">
                            <h5 className="text-center">Присоединяйся и играй с нами!</h5>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-sm-4">
                            <div className="shadow-lg p-25 shadow-showcase text-center">
                                <h5>Атмосфера</h5>
                                <p>Главное внимание уделяется созданию уникальной атмосферы и глубокой проработке персонажей в игровом мире. Мы предоставим вам все возможности для полного раскрытия вашего героя, чтобы каждый момент игры приносил новые эмоции и впечатления.</p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="shadow-lg p-25 shadow-showcase text-center">
                                <h5>Основной сюжет</h5>
                                <p>Каждую сессию мы создаем уникальный сюжет, чтобы вы могли пробовать новые роли и получать неповторимый опыт на протяжении всей игры. Развитие сюжета полностью зависит от решений и действий игроков; наша команда лишь задает начало увлекательной или ужасающей истории, предоставляя вам возможность влиять на её развитие.</p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="shadow-lg p-25 shadow-showcase text-center">
                                <h5>Команда проекта</h5>
                                <p>Команда проекта в первую очередь ставит развитие персонажей на сервере, каждая история должна быть уникальна. Мы стремимся обеспечить разнообразие сюжетных линий, чтобы каждый игрок мог почувствовать себя частью живого и динамичного мира. Наша цель – создать условия, в которых ваши персонажи будут обретать новые черты и взаимодействовать с другими, формируя уникальные истории и взаимоотношения.</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}