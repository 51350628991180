import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { useGetCreateCharacterQuestionsQuery, useUpdateCharacterMutation } from "../../../store/api/charactersApi"
import { CharacterModel } from "../../../types/models"
import { toast } from "react-toastify"
import { handlerApiErrors } from "../../../vendor/handlerApiErrors"

type FormValues = {
    firstName: string
    secondName: string
    personality: string
    appearance: string
    notableSkills: string
    background: string
    questions: {
        [key: string]: string
    }
}
export type UpdateCharacterModalProps = {
    character: CharacterModel<'get'>
}
export const UpdateCharacterModal = ({character}: UpdateCharacterModalProps) => {

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(s => !s);
    const {isLoading, isFetching, isSuccess, isError, data: questions} = useGetCreateCharacterQuestionsQuery(undefined, {
        skip: !isOpen
    });
    const [updateCharacter] = useUpdateCharacterMutation();

    const {
        register,
        formState: {errors},
        handleSubmit,
        setValue
    } = useForm<FormValues>();

    useEffect(() => {
        if(isOpen)
        {
            const [firstName, secondName] = character.name.split(' ');
            setValue('firstName', firstName);
            setValue('secondName', secondName);
            for(const key of ['appearance', 'background', 'notableSkills', 'personality'] as Array<'appearance' | 'background' | 'notableSkills' | 'personality'>)
            {
                setValue(key, character[key]);
            }  
        }
    }, [isOpen]);

    const update = (data: FormValues) => {
        data.firstName = data.firstName.trim();
        data.secondName = data.secondName.trim();
        updateCharacter({
            id: character.id,
            data: {
                firstName: data.firstName,
                secondName: data.secondName,
                personality: data.personality,
                appearance: data.appearance,
                notableSkills: data.notableSkills,
                background: data.background,
                answersQuestions: Object.entries(data.questions).map(([questionId, text]) => ({questionId, text}))
            }
        }).unwrap()
            .then(character => {
                toast.success("Заявка отправлена");
                toggle();
            })
            .catch(r => toast.error(handlerApiErrors(r)));
    }

    return (
        <>
            <button onClick={toggle} className="btn btn-outline-primary">Подать заявку снова</button>
            <Modal fade={false} isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Подать заявку снова</ModalHeader>
                <ModalBody>
                    <form onSubmit={handleSubmit(update)}>
                        <h3>Основная информация</h3>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group form-row">
                                    <label htmlFor="" className="col-form-label">Имя</label>
                                    <input {...register('firstName', {required: "Введите имя"})} type="text" className="form-control" />
                                    {errors.firstName && <span className="txt-danger">{errors.firstName.message}</span>}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group form-row">
                                    <label htmlFor="" className="col-form-label">Фамилия либо прозвище</label>
                                    <input {...register('secondName', {required: "Введите фамилию либо прозвище"})} type="text" className="form-control" />
                                    {errors.secondName && <span className="txt-danger">{errors.secondName.message}</span>}
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="form-group form-row">
                            <label htmlFor="" className="col-form-label">Личность</label>
                            <textarea {...register('personality', {required: "Заполните поле \"Личность\""})}  rows={10} className="form-control"></textarea>
                            {errors.personality && <span className="txt-danger">{errors.personality.message}</span>}
                        </div>
                        <br />
                        <div className="form-group form-row">
                            <label htmlFor="" className="col-form-label">Появление в игровом мире</label>
                            <textarea {...register('appearance', {required: "Заполните поле \"Появление в игровом мире\""})}  rows={10} className="form-control"></textarea>
                            {errors.appearance && <span className="txt-danger">{errors.appearance.message}</span>}
                        </div>
                        <br />
                        <div className="form-group form-row">
                            <label htmlFor="" className="col-form-label">Основные навыки</label>
                            <textarea {...register('notableSkills', {required: "Заполните поле \"Основные навыки\""})}  rows={10} className="form-control"></textarea>
                            {errors.notableSkills && <span className="txt-danger">{errors.notableSkills.message}</span>}
                        </div>
                        <br />
                        <div className="form-group form-row">
                            <label htmlFor="" className="col-form-label">История персонажа</label>
                            <textarea {...register('background', {required: "Заполните квенту"})}  rows={10} className="form-control"></textarea>
                            {errors.background && <span className="txt-danger">{errors.background.message}</span>}
                        </div>
                        <br />
                        <h3>Дополнительная информация</h3>
                        {(!(isLoading || isFetching) && isSuccess) && questions.map(quest => 
                            <div className="form-group form-row">
                                <label htmlFor="" className="col-form-label">{quest.text}</label>
                                <textarea {...register(`questions.${quest.id}`, {required: 'Ответьте на вопрос'})} rows={3} className="form-control"></textarea>
                                {errors.questions && quest.id in errors.questions && <span className="txt-danger">{errors.questions[quest.id]?.message}</span>}
                            </div>  
                        )}
                        <br />
                        <div className="d-grid">
                            <button className="btn btn-primary">Отправить</button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </>
    )
}