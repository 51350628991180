import React, { Fragment, useState, useEffect, SetStateAction } from 'react';
// import { useSelector } from 'react-redux';
import logo from '../../../assets/images/endless-logo.png';
import logo_compact from '../../../assets/images/logo/compact-logo.png';
import UserPanel from './userPanel';
import { MenuItem } from './menu';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Airplay, Info } from 'react-feather';
import { useAuth } from '../../../hooks/useAuth';
import { MainMenuState } from '../../app';
// import { useTranslation } from 'react-i18next';
// import configDB from '../../../data/customizer/config';
import config from '../../../config.json';


const Sidebar = ({mainmenu, setMainMenu}: {
    mainmenu: MainMenuState,
    setMainMenu: React.Dispatch<SetStateAction<MainMenuState>>
}) => {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        console.log('set pathname', location.pathname);

        mainmenu.mainmenu.forEach(a => {
                if(a.type != 'sub') a.active = false;
                
            if (!a.children) return false
            a.children.forEach(b => {
                    b.active = false
                if (!b.children) return false
                b.children.forEach(c => {
                        c.active = false
                })
            })
        });

        mainmenu.mainmenu.forEach(a => {
            if(a.type == 'link')
            {
                if(RegExp(a.pathRegExp ? a.pathRegExp : `^${a.path}(.+)?`).test(location.pathname))
                    a.active = true;
            }
            else if(a.type == 'sub')
            {
                a.children?.forEach(b => {
                    if(RegExp(b.pathRegExp ? b.pathRegExp : `^${b.path}(.+)?`).test(location.pathname))
                    {
                        b.active = true;
                        a.active = true;
                    }
                })
            }
        });

        setMainMenu({ mainmenu: mainmenu.mainmenu });
    }, [location.pathname])
    const toggletNavActive = (item: MenuItem) => {
        if(item.type == 'sub') 
        {
            item.active = !item.active;
            setMainMenu({ mainmenu: mainmenu.mainmenu });
        }
        
        
        
    }

    
    return (
        <Fragment>
            <div className="page-sidebar">
                <div className="main-header-left d-none d-lg-block">
                    <div className="logo-wrapper compactLogo">
                        {/* <Link to={`/dashboard/default`}>
                            <img className="blur-up lazyloaded" src={logo_compact} alt="" />
                            <img className="blur-up lazyloaded" src={logo} alt="" />
                        </Link> */}
                    </div>
                </div>
                <div className="sidebar custom-scrollbar">
                    <UserPanel />
                    <ul className="sidebar-menu" id="myDIV" style={{margin: '0px'}}>
                            <li>
                                <a className='sidebar-header' href={config.forumURL} target='_blank'>
                                    <Info/>
                                    <span>Форум</span>
                                </a>
                            </li>
                    {
                            mainmenu?.mainmenu?.map((menuItem, i) =>
                                <li className={`${menuItem.active ? 'active' : ''}`} key={i}>
                            
                                    {(menuItem.type === 'sub') ?
                                        <a className="sidebar-header" href="#" onClick={() => toggletNavActive(menuItem)}>
                                            <menuItem.icon />
                                            <span>{menuItem.title}</span>
                                            <i className="fa fa-angle-right pull-right"></i>
                                        </a>
                                        : ''}
                                    {(menuItem.type === 'link') ?
                                        <Link
                                            to={`${menuItem.path}`}
                                            className={`sidebar-header ${menuItem.active ? 'active' : ''}`}

                                            onClick={() => toggletNavActive(menuItem)}
                                        >
                                            <menuItem.icon /><span>{menuItem.title}</span>
                                            {menuItem.children ?
                                                <i className="fa fa-angle-right pull-right"></i> : ''}
                                        </Link>
                                        : ''}
                                    {menuItem.children ?
                                        <ul
                                            className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''}`}
                                            style={menuItem.active ? { opacity: 1, transition: 'opacity 500ms ease-in' } : {}}
                                        >
                                            {menuItem.children.map((childrenItem, index) =>
                                                <li key={index} className={childrenItem.active ? 'active' : ''} onClick={() => {
                                                    navigate(`${childrenItem.path}`);
                                                    toggletNavActive(childrenItem);
                                                }}>
                                                    {(childrenItem.type === 'sub') ?
                                                        <a href="#" onClick={() => toggletNavActive(childrenItem)} >
                                                            <i className="fa fa-circle"></i>{childrenItem.title} <i className="fa fa-angle-right pull-right"></i></a>
                                                        : ''}

                                                    {(childrenItem.type === 'link') ?
                                                        <Link
                                                            to={`${childrenItem.path}`}
                                                            className={childrenItem.active ? 'active' : ''}
                                                        >
                                                            <i className="fa fa-circle"></i>{childrenItem.title} </Link>
                                                        : ''}
                                                    {childrenItem.children ?
                                                        <ul className={`sidebar-submenu ${childrenItem.active ? 'menu-open' : 'active'}`}>
                                                            {childrenItem.children.map((childrenSubItem, key) =>
                                                                <li className={childrenSubItem.active ? 'active' : ''} key={key}>
                                                                    {(childrenSubItem.type === 'link') ?
                                                                        <Link
                                                                            to={`${childrenSubItem.path}`}
                                                                            className={childrenSubItem.active ? 'active' : ''}
                                                                            onClick={() => toggletNavActive(childrenSubItem)}
                                                                        >
                                                                            <i className="fa fa-circle"></i>{childrenSubItem.title}</Link>
                                                                        : ''}
                                                                </li>
                                                            )}
                                                        </ul>
                                                        : ''}
                                                </li>
                                            )}
                                        </ul>
                                        : ''}
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </Fragment>
    );
};

export default Sidebar;

