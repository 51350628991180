import { useEffect, useState } from "react"
import { RegistrationQuestionModel } from "../types/models";
import axios from "axios";
import config from '../config.json';
import { toast } from "react-toastify";
import { RegisterFormQuestions } from "../components/pages/register/formQuestions";
import { RegisterFormCreateAccount } from "../components/pages/register/formCreateAccount";

export const RegisterPage = () => {

    const [registerToken, setRegisterToken] = useState<string | null>(null);
    

    

    

    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h4>Регистрация</h4>
                                                    <h6>
                                                    {
                                                    registerToken
                                                    ? 'Введите данные в необходимые поля для регистрации'
                                                    : 
                                                    <>
                                                    Прежде чем пройти регистрацию, ответьте на следующие вопросы. У каждого вопроса может быть несколько правильных ответов.
                                                    <br /><br />
                                                    Правила можно посмотреть на <a href={config.forumURL} target="_blank">форуме</a>
                                                    <br /><br />
                                                    </>
                                                    }
                                                    </h6>
                                                </div>
                                                {registerToken
                                                    ? <RegisterFormCreateAccount token={registerToken}/>
                                                    : <RegisterFormQuestions setRegisterToken={setRegisterToken}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}