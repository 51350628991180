import React, { SetStateAction, createContext } from "react";
import { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { UserModel } from "../types/models";
import { checkRefreshTokenAndAuthUser, refreshToken } from "../store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "../hooks/redux";

export interface AuthContextProps {

}

export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthProvider = ({children}: {children: React.ReactNode}) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const isAuth = useAppSelector(state => state.user.isAuth);
    useEffect(() => {
        (async () => {
            dispatch(checkRefreshTokenAndAuthUser()).unwrap()
                .then(() => {
                    setTimeout(() => navigate(localStorage.getItem('first_pathname') ?? ''), 200);
                })
                .catch(() => {});
        })()
    }, []);

    useEffect(() => {
        let interval: any;
        if(isAuth)
        {
            interval = setInterval(() => {
                dispatch(refreshToken())
            }, (60 * 1000) * 7);
        }

        return () => {
            if(interval) clearInterval(interval);
        }
    }, [isAuth])
    return (
        <AuthContext.Provider value={{}}>
            {children}
        </AuthContext.Provider>
    );
}

