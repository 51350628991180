import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import defaultPrepareHeaders from '../defaultPrepareHeaders';
import config from '../../config.json';
import { CharacterModel, CreateCharacterQuestionModel } from '../../types/models';

export type CreateCharacterParams = {
    firstName: string
    secondName: string
    personality: string
    appearance: string
    notableSkills: string
    background: string
    answersQuestions: {
        questionId: string
        text: string
    }[]
}

const charactersApi = createApi({
    reducerPath: 'characters',
    baseQuery: fetchBaseQuery({
        prepareHeaders: defaultPrepareHeaders,
        baseUrl: `${config.mainURL}/characters`
    }),
    tagTypes: ['Characters'],
    endpoints: build => ({
        getCharacters: build.query<CharacterModel<'list'>[], void>({
            query: () => '',
            providesTags: ['Characters']
        }),
        getCharacter: build.query<CharacterModel<'get'>, string>({
            query: id => '/' + id,
            providesTags: ['Characters']
        }),
        createCharacter: build.mutation<CharacterModel<'get'>, CreateCharacterParams>({
            query: body => ({
                url: '/',
                method: "POST",
                body
            }),
            invalidatesTags: ['Characters']
        }),
        getCreateCharacterQuestions: build.query<CreateCharacterQuestionModel[], void>({
            query: () => '/create-questions'
        }),
        updateCharacter: build.mutation<CharacterModel<'get'>, {id: string, data: CreateCharacterParams}>({
            query: ({id, data}) => ({
                url: '/' + id,
                method: "PATCH",
                body: data
            }),
            invalidatesTags: ['Characters']
        })
    })
});

export const {
    useGetCharactersQuery,
    useGetCharacterQuery,
    useCreateCharacterMutation,
    useGetCreateCharacterQuestionsQuery,
    useUpdateCharacterMutation
} = charactersApi;

export default charactersApi;

