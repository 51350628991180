import { Clipboard, CreditCard, Crosshair, Database, DollarSign, HelpCircle, Home, Icon, Inbox, Info, Key, Link, Monitor, Package, Settings, Tablet, TrendingUp, User, Users } from 'react-feather';

export type MenuItemType = 'link' | 'sub';

export type MenuItem = {
    title: string
    icon: Icon
    type: MenuItemType
    active: boolean
    children?: Array<MenuItem>
    bookmark?: boolean
    path?: string
    pathRegExp?: RegExp
}

export const getMenu = (): MenuItem[] => {
    let menuitems: MenuItem[] = [];
    for(const item of MENUITEMS)
    {
        menuitems.push(item);
    }
    
    return menuitems;
}

const MENUITEMS: Array<MenuItem> = [
    {
        title: 'Персонажи', 
        icon: Users, 
        type: 'link', 
        path: '/characters', 
        active: false, 
        bookmark: true
    },
    {
        title: 'Доп. услуги', 
        icon: Inbox, 
        type: 'link', 
        path: '/donate', 
        active: false, 
        bookmark: true
    }
]

// const MENUITEMS = [
//     {
//         access: 'setting:view',
//         body: {
//             title: 'Статистика', icon: TrendingUp, type: 'link', path: '/statistic', active: false, bookmark: true
//         },
//     },
//     {
//         access: ['budget:view', 'budget-virtual-cards:view', 'budget:history-subs'],
//         body: {
//             title: 'Бюджет', icon: DollarSign, type: 'sub', badgeType: 'primary', active: false, children: [
//                 { path: '/budget/main', title: 'Платежные системы', type: 'link', active: false, access: 'budget:view' },
//                 { path: '/budget/cards', title: 'Карты и криптовалюты', type: 'link', active: false, access: 'budget-virtual-cards:view' },
//                 { path: '/budget/history_subs', title: 'История покупок', type: 'link', active: false, access: 'budget:history-subs' },
//             ]
//         }
//     },
//     {
//         access: 'cab-help:view',
//         body: {
//             title: 'Инструкции', icon: HelpCircle, type: 'link', path: '/cab-help', active: false, bookmark: true
//         }
//     },
//     {
//         access: ['keys:index', 'keys:search'],
//         body: {
//             title: 'Промокоды', icon: Key, type: 'link', path: '/keys', active: false, bookmark: true
//         }
//     },
//     {
//         access: ['users:index', 'users:search'],
//         body: {
//             title: 'Пользователи', icon: User, type: 'link', path: '/users', active: false, bookmark: true
//         }
//     },
//     {
//         access: 'sn:view',
//         body: {
//             title: 'Модераторы', icon: Monitor, type: 'sub', badgeType: 'primary', active: false, children: [
//                 { path: '/sn/ap', title: 'Авто-постинг', type: 'link', active: false },
//                 { path: '/sn/vk', title: 'VK', type: 'link', active: false },
//                 { path: '/sn/discord', title: 'Discord', type: 'link', active: false },
//             ]
//         }
//     },
//     {
//         access: 'employee:view',
//         body: {
//             title: 'Сотрудники', icon: Users, type: 'link', path: '/employees', active: false, bookmark: true, pathRegExp: "^/employees(/positions/[0-9]+|/[0-9]+)?$"
//         }
//     },
//     {
//         access: 'all',
//         body: {
//             title: 'Идеи сотрудников', icon: Info, type: 'link', path: '/employees/ideas', active: false, bookmark: true
//         }
//     },
//     {
//         access: 'fullaccess',
//         body: {
//             title: 'Настройки сайта', icon: Settings, type: 'link', path: '/site_setting', active: false, bookmark: true
//         }
//     },
//     {
//         access: 'info:set',
//         body: {
//             title: 'Базы знаний', icon: Clipboard, type: 'sub', badgeType: 'primary', active: false, children: [
//                 { path: '/knowlengebases/global', title: 'Общие', type: 'link', active: false },
//                 { path: '/knowlengebases/corporative', title: 'Корпоративные', type: 'link', active: false },
//             ]
//         }
//     },
//     {
//         access: 'fullaccess',
//         body: {
//             title: 'Лоадер', icon: Tablet, type: 'sub', badgeType: 'primary', active: false, children: [
//                 { path: '/loader/roles', title: 'Роли', type: 'link', active: false },
//                 { path: '/loader/products', title: 'Продукты', type: 'link', active: false },
//             ]
//         }
//     },
//     {
//         access: 'fullaccess',
//         body: {
//             title: 'Steam', icon: Crosshair, type: 'sub', badgeType: 'primary', active: false, children: [
//                 { path: '/steam/monitoring_apps', title: 'Приложения', type: 'link', active: false },
//             ]
//         }
//     },
//     {
//         access: 'fullaccess',
//         body: {
//             title: 'Документация API', icon: Settings, type: 'link', path: '/docs', active: false, bookmark: true
//         }
//     },
//     {
//         access: 'fullaccess',
//         body: {
//             title: 'Файловый менеджер', icon: Database, type: 'link', path: '/filemanager', active: false, bookmark: true
//         }
//     },
//     {
//         access: 'chats:use',
//         body: {
//             title: 'Чат поддержки', icon: Database, type: 'link', path: '/chat', active: false, bookmark: true
//         }
//     }
// ]
