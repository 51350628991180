import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import { ToastContainer } from 'react-toastify';

localStorage.setItem('first_pathname', window.location.pathname);

ReactDOM.render(
  <React.StrictMode>
    <App />
    <ToastContainer
      theme='dark'
    />
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
