import { useParams } from "react-router-dom";
import { useGetCharacterQuery } from "../../store/api/charactersApi";
import Breadcrumb from "../../components/common/breadcrumb";
import { Link } from "react-router-dom";
import { CharacterStatus } from "../../types/models";
import { CharacterLoginDetailsModal } from "../../components/pages/characters/modalLoginDetails";
import statusesCharacter from "../../vendor/strings/statusesCharacter";
import { UpdateCharacterModal } from "../../components/pages/characters/modalUpdateCharacter";

export const CharacterPage = () => {

    const {characterId} = useParams() as {
        characterId: string
    }
    const {isError, isLoading, isSuccess, data: character} = useGetCharacterQuery(characterId);

    const statusColors = {
        [CharacterStatus.PENDING]: 'secondary',
        [CharacterStatus.APPROVED]: "success",
        [CharacterStatus.REJECTED]: 'danger',
        [CharacterStatus.KILLED]: 'danger',
        [CharacterStatus.ARCHIVED]: 'danger'
    }

    return (
        <>
            <Breadcrumb title={isSuccess ? character.name : 'Загрузка...'} parent={<Link to='/characters'>Персонажи</Link>}/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        {isSuccess && 
                            <div className={`alert alert-${statusColors[character.status]} outline fade show`}>
                                <p>
                                    Персонаж {statusesCharacter[character.status].toLowerCase()}. 
                                    {character.comments.length > 0 && 
                                        <>
                                            <br />
                                            Комментарий от {character.comments[0].author}: {character.comments[0].text}
                                        </>
                                    }
                                </p>
                            </div>
                        }
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-sm-8">
                                        <h5>информация о персонаже</h5>
                                    </div>
                                    <div className="col-sm-4 d-flex justify-content-end btn-group">
                                        {isSuccess &&
                                        <>
                                            {character.status === CharacterStatus.REJECTED && <UpdateCharacterModal character={character}/>}
                                            {character.status === CharacterStatus.APPROVED && <CharacterLoginDetailsModal character={character}/>}
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                            {isSuccess &&
                            <>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="shadow-lg p-25 shadow-showcase text-center">
                                            <h5>Имя и фамилия</h5>
                                            <p>{character.name}</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="shadow-lg p-25 shadow-showcase text-center">
                                            <h5>Создан</h5>
                                            <p>{character.createdAt}</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="shadow-lg p-25 shadow-showcase text-center">
                                            <h5>Сыграно</h5>
                                            <p>{character.gameHours}</p>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="shadow-lg p-25 shadow-showcase">
                                    <h5 className="text-center">Личность</h5>
                                    <p>{character.personality}</p>
                                </div>
                                <br />
                                <div className="shadow-lg p-25 shadow-showcase">
                                    <h5 className="text-center">Появление в игровом мире</h5>
                                    <p>{character.appearance}</p>
                                </div>
                                <br />
                                <div className="shadow-lg p-25 shadow-showcase">
                                    <h5 className="text-center">Основные навыки</h5>
                                    <p>{character.notableSkills}</p>
                                </div>
                                <br />
                                <div className="shadow-lg p-25 shadow-showcase">
                                    <h5 className="text-center">История персонажа</h5>
                                    <p>{character.background}</p>
                                </div>
                            </>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    );
}