import { Link, useNavigate } from "react-router-dom"
import Breadcrumb from "../../components/common/breadcrumb"
import { useRef, useState } from "react"
import { useCreateCharacterMutation, useGetCreateCharacterQuestionsQuery } from "../../store/api/charactersApi";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { handlerApiErrors } from "../../vendor/handlerApiErrors";
import { Modal, ModalBody, ModalHeader } from "reactstrap";


type FormValues = {
    firstName: string
    secondName: string
    personality: string
    appearance: string
    notableSkills: string
    background: string
    questions: {
        [key: string]: string
    }
}

export const CharacterCreatePage = () => {

    const [helpModal, setHelpModal] = useState(false);
    const toggleHelpModal = () => setHelpModal(s => !s);
    const {isLoading, isFetching, isSuccess, isError, data: questions} = useGetCreateCharacterQuestionsQuery();
    const [createCharacter] = useCreateCharacterMutation();

    const navigate = useNavigate();

    const create = (data: FormValues) => {
        data.firstName = data.firstName.trim();
        data.secondName = data.secondName.trim();
        createCharacter({
            firstName: data.firstName,
            secondName: data.secondName,
            personality: data.personality,
            appearance: data.appearance,
            notableSkills: data.notableSkills,
            background: data.background,
            answersQuestions: Object.entries(data.questions).map(([questionId, text]) => ({questionId, text}))
        }).unwrap()
            .then(character => {
                navigate('/characters/' + character.id);
                toast.success("Персонаж успешно создан")
            })
            .catch(r => toast.error(handlerApiErrors(r)));
    }

    const {
        register,
        formState: {errors},
        handleSubmit
    } = useForm<FormValues>();


    return (
        <>
            <Breadcrumb title="Создать" parent={<Link to="/characters">Персонажи</Link>}/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <h5>Основная информация</h5>
                                    </div>
                                    <div className="col-sm-8 d-flex justify-content-end">
                                        <button onClick={toggleHelpModal} className="btn btn-outline-primary">Помощь по заполнению</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group form-row">
                                                <label htmlFor="" className="col-form-label">Имя</label>
                                                <input {...register('firstName', {required: "Введите имя"})} type="text" className="form-control" />
                                                {errors.firstName && <span className="txt-danger">{errors.firstName.message}</span>}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group form-row">
                                                <label htmlFor="" className="col-form-label">Фамилия либо прозвище</label>
                                                <input {...register('secondName', {required: "Введите фамилию либо прозвище"})} type="text" className="form-control" />
                                                {errors.secondName && <span className="txt-danger">{errors.secondName.message}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-group form-row">
                                        <label htmlFor="" className="col-form-label">Личность</label>
                                        <textarea {...register('personality', {required: "Заполните поле \"Личность\""})}  rows={10} className="form-control"></textarea>
                                        {errors.personality && <span className="txt-danger">{errors.personality.message}</span>}
                                    </div>
                                    <br />
                                    <div className="form-group form-row">
                                        <label htmlFor="" className="col-form-label">Появление в игровом мире</label>
                                        <textarea {...register('appearance', {required: "Заполните поле \"Появление в игровом мире\""})}  rows={10} className="form-control"></textarea>
                                        {errors.appearance && <span className="txt-danger">{errors.appearance.message}</span>}
                                    </div>
                                    <br />
                                    <div className="form-group form-row">
                                        <label htmlFor="" className="col-form-label">Основные навыки</label>
                                        <textarea {...register('notableSkills', {required: "Заполните поле \"Основные навыки\""})}  rows={10} className="form-control"></textarea>
                                        {errors.notableSkills && <span className="txt-danger">{errors.notableSkills.message}</span>}
                                    </div>
                                    <br />
                                    <div className="form-group form-row">
                                        <label htmlFor="" className="col-form-label">История персонажа</label>
                                        <textarea {...register('background', {required: "Заполните квенту"})}  rows={10} className="form-control"></textarea>
                                        {errors.background && <span className="txt-danger">{errors.background.message}</span>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>Дополнительная информация</h5>
                            </div>
                            <div className="card-body">
                                {(isLoading || isFetching) && 'Загрузка вопросов...'}
                                {(!(isLoading || isFetching) && isSuccess) && questions.map(quest => 
                                    <div className="form-group form-row">
                                        <label htmlFor="" className="col-form-label">{quest.text}</label>
                                        <textarea {...register(`questions.${quest.id}`, {required: 'Ответьте на вопрос'})} rows={3} className="form-control"></textarea>
                                        {errors.questions && quest.id in errors.questions && <span className="txt-danger">{errors.questions[quest.id]?.message}</span>}
                                    </div>  
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={handleSubmit(create)}>
                                    <div className="d-grid">
                                        <button className="btn btn-primary">Создать</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={helpModal} toggle={toggleHelpModal} fade={false}>
                <ModalHeader toggle={toggleHelpModal}>Помощь по заполнению</ModalHeader>
                <ModalBody>
                    <p>
                        <h5>Личность</h5>
                        <b>Подсказка:</b> Опишите характер и черты вашего персонажа. Как он или она взаимодействует с другими? Какие у него или у неё основные черты характера?
                        <br /><br />
                        <b>Объяснение:</b> В этом поле вам нужно рассказать о том, каким человеком является ваш персонаж. Он может быть добрым и сострадательным или, наоборот, замкнутым и подозрительным. Подумайте о его мотивах, страхах и желаниях.
                    </p>
                    <br />
                    <p>
                        <h5>Появление в игровом мире</h5>
                        <b>Подсказка:</b> Опишите, как ваш персонаж попал в игровой мир. Каково его первое впечатление и с чем он сразу столкнулся?
                        <br /><br />
                        <b>Объяснение:</b> В этом поле расскажите историю того, как ваш персонаж оказался в мире игры. Это может быть драматический момент, как он спасается от зомби, или более спокойное появление, как он начинает свое путешествие.
                    </p>
                    <br />
                    <p>
                        <h5>Основные навыки</h5>
                        <b>Подсказка:</b> Опишите, какими умениями и навыками владеет ваш персонаж. В чем он или она особенно хорош?
                        <br /><br />
                        <b>Объяснение:</b> Здесь вам нужно выделить ключевые навыки вашего персонажа, которые помогут ему выживать и взаимодействовать в игровом мире. Это могут быть как боевые навыки, так и навыки выживания или медицинские знания. 
                    </p>
                    <br />
                    <p>
                        <h5>История персонажа</h5>
                        <b>Подсказка:</b> Расскажите предысторию вашего персонажа. Какие события и люди сформировали его личность?
                        <br /><br />
                        <b>Объяснение:</b> В этом разделе раскройте биографию вашего персонажа, начиная с его прошлого до момента появления в игровом мире. Подумайте о значимых событиях и людях, которые повлияли на его жизненный путь.
                    </p>
                </ModalBody>
            </Modal>
        </>
    )
}