import { AxiosResponse } from "axios";

export const handlerApiErrors = (response: AxiosResponse): string => {

    if('message' in response.data)
    {
        return (Array.isArray(response.data.message))
            ? response.data.message[0]
            : response.data.message;
    }
    else return 'Неизвестная ошибка';
}