
import { combineReducers, configureStore, createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "../types/store";
import userSlice from "./slices/userSlice";
import charactersApi from "./api/charactersApi";
import infoApi from "./api/infoApi";



const rootReducer = combineReducers({
    user: userSlice,
    [charactersApi.reducerPath]: charactersApi.reducer,
    [infoApi.reducerPath]: infoApi.reducer
});


export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        charactersApi.middleware,
        infoApi.middleware
    ),
    devTools: true
});
