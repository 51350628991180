import axios from 'axios';
import {Route, BrowserRouter, Routes } from 'react-router-dom';
import AppLayout from './components/app';
import { AuthMiddleware } from './middlewares/AuthMiddleware';
import { GuestMiddleware } from './middlewares/GuestMiddleware';
import { Error404 } from './pages/errors/404';
import { AuthProvider } from './providers/AuthProvider';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { IndexPage } from './pages';
import { RegisterPage } from './pages/register';
import { LoginPage } from './pages/login';
import { CharacterCreatePage } from './pages/characters/create';
import { CharactersListPage } from './pages/characters/list';
import { CharacterPage } from './pages/characters/page';
import { HomePage } from './pages/home';

const App = () => {

  axios.defaults.validateStatus = (status) => status >= 200;
  axios.defaults.headers.common['Authorization'] = localStorage.getItem('jwt_access_token') ?? '';
  return (
        <Provider store={store}>
          <BrowserRouter>
            <AuthProvider>
              <Routes>
                <Route element={<AuthMiddleware/>}>
    
                  <Route element={<AppLayout/>}>
                      <Route path="/home" element={<HomePage/>}/>

                      <Route path='/characters/create' element={<CharacterCreatePage/>}/>
                      <Route path='/characters' element={<CharactersListPage/>}/>
                      <Route path='/characters/:characterId' element={<CharacterPage/>}/>
                  </Route>
                </Route>
                <Route path='/' element={<IndexPage/>}/>
                <Route element={<GuestMiddleware/>}>
                  <Route path='/auth' element={<LoginPage/>}/>
                  <Route path='/register' element={<RegisterPage/>}/>
                </Route>
                <Route path='*' element={<Error404/>}/>
              </Routes>
            </AuthProvider>
          </BrowserRouter>
        </Provider>
  );
}

export default App;
