import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserModel } from "../../types/models";
import axios from "axios";
import { api } from "../../vendor/handlerAuth";
import config from '../../config.json';
import { handlerApiErrors } from "../../vendor/handlerApiErrors";
import { jwtDecode } from "jwt-decode";
import { msContainsItem, msDeleteItem, msGetItem, msSetItem } from "../../vendor/handlerStorage";
import { store } from "../store";

export type AuthUserParams = {
    login?: string
    email?: string
    password: string
}

export type RegisterUserParams = {
    login: string
    email: string
    password: string
    token: string
}

export type UserSliceState = {
    user: UserModel | null
    isAuth: boolean
    isChecked: boolean
}

const saveAccessAndRefreshTokenToLocalStorage = (accessToken: string, refreshToken: string) => {
    msSetItem('accessToken', accessToken, Math.abs(Date.now() / 1000) + 60*10);
    msSetItem('refreshToken', refreshToken, Math.abs(Date.now() / 1000) + 60*60*47);
}

export const checkRefreshTokenAndAuthUser = createAsyncThunk('user/checkRefreshTokenAndAuthUser', async (_, thunkApi) => {
    if(msContainsItem('refreshToken'))
    {
        const response = await axios.post(`${config.mainURL}/auth/refresh`, {
            refreshToken: msGetItem('refreshToken')
        });
        if(response.status != 200) 
        {
            msDeleteItem('refreshToken');
            return thunkApi.rejectWithValue(undefined);
        }
        else
        {
            saveAccessAndRefreshTokenToLocalStorage(response.data.accessToken, response.data.refreshToken);
            return {accessToken: response.data.accessToken};
        }
    }
    else return thunkApi.rejectWithValue(undefined);
});

export const authUser = createAsyncThunk('user/authUser', async (data: AuthUserParams, thunkApi) => {
    const response = await api().post<{
        accessToken: string
        refreshToken: string
    }>(`${config.mainURL}/auth`, data);
    if(response.status != 200) return thunkApi.rejectWithValue({error: handlerApiErrors(response), httpCode: response.status});
    else return response.data;
});

export const registerUser = createAsyncThunk('user/registerUser', async (data: RegisterUserParams, thunkApi) => {
    const response = await api().post<{
        accessToken: string
        refreshToken: string
    }>(`${config.mainURL}/register`, data);
    if(response.status != 201) return thunkApi.rejectWithValue({error: handlerApiErrors(response)});
    else return response.data;
});

export const refreshToken = createAsyncThunk('user/refreshToken', async (_, thunkApi) => {
    if(msContainsItem('refreshToken'))
    {
        const response = await axios.post(`${config.mainURL}/auth/refresh`, {
            refreshToken: msGetItem('refreshToken')
        });
        if(response.status != 200) 
        {
            msDeleteItem('refreshToken');
            return thunkApi.rejectWithValue(undefined);
        }
        else
        {
            return response.data;
        }
    }
    else return thunkApi.rejectWithValue(undefined);
});


const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null,
        isAuth: false,
        isChecked: false
    } as UserSliceState,
    reducers: {
        logout: (state) => {
            state.user = null;
            state.isAuth = false;
            msDeleteItem('accessToken');
            msDeleteItem('refreshToken');
        }
    },
    extraReducers: (builder) => {
        builder


            .addCase(registerUser.fulfilled, (state, {payload}) => {
                const dataAccess = jwtDecode(payload.accessToken) as {
                    user: UserModel
                };
                
                saveAccessAndRefreshTokenToLocalStorage(payload.accessToken, payload.refreshToken);
                state.isAuth = true;
                state.user = dataAccess.user;
            })


            .addCase(authUser.fulfilled, (state, {payload}) => {
                const dataAccess = jwtDecode(payload.accessToken) as {
                    user: UserModel
                };
                
                saveAccessAndRefreshTokenToLocalStorage(payload.accessToken, payload.refreshToken);
                state.isAuth = true;
                state.user = dataAccess.user;
                
            })


            .addCase(checkRefreshTokenAndAuthUser.rejected, (state) => {
                state.isChecked = true;
            })

            .addCase(checkRefreshTokenAndAuthUser.fulfilled, (
                state, 
                {payload}: PayloadAction<{accessToken: string}>
            ) => {
                const dataAccess = jwtDecode(payload.accessToken) as {
                    user: UserModel
                };

                state.isChecked = true;
                state.isAuth = true;
                state.user = dataAccess.user;
            })


            .addCase(refreshToken.fulfilled, (
                state, 
                {payload}: PayloadAction<{accessToken: string, refreshToken: string}>
            ) => {
                const dataAccess = jwtDecode(payload.accessToken) as {
                    user: UserModel
                };
                
                saveAccessAndRefreshTokenToLocalStorage(payload.accessToken, payload.refreshToken);
                state.isChecked = true;
                state.isAuth = true;
                state.user = dataAccess.user;
            })

            .addCase(refreshToken.rejected, (state, _) => {
                msDeleteItem('refreshToken');
                msDeleteItem('accessToken');
                state.isAuth = false;
                state.user = null;
            });
    }
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;