import DataTable from "react-data-table-component";
import { useGetOnlineInfoQuery } from "../../../store/api/infoApi"
import accessLevelsEmployee from "../../../vendor/strings/accessLevelsEmployee";
import { Eye } from "react-feather";
import config from '../../../config.json';
import { useEffect } from "react";

export const OnlineInfoCard = () => {

    const {isLoading, isError, isSuccess, data, refetch} = useGetOnlineInfoQuery();

    useEffect(() => {
        let interval: any;
        if(isSuccess)
        {
            refetch();
            let blocked = false;
            interval = setInterval(() => {
                if(blocked) return;
                blocked = true;

                refetch().unwrap()
                    .then(() => (blocked = false))
                    .catch(() => (blocked = false));
            }, 60_000);
        }
        return () => {
            if(interval) clearInterval(interval);
        }
    }, [isSuccess])

    return (
        <div className="card">
            <div className="card-header">
                {isSuccess &&
                    <div className="row">
                        <div className="col-sm-4">
                            <h5>Онлайн сервера [{data.users.length + data.employees.length} / 32]</h5>
                        </div>
                        <div className="col-sm-8 text-end">
                            {data.createdAt}
                        </div>
                    </div>
                }
                {isLoading && 'Загрузка...'}
            </div>
            {isSuccess &&
            <div className="card-body">
                <div className="shadow-lg p-25 shadow-showcase">
                    <h3><b>Игроки [{data.users.length}]</b></h3>
                    {data.users.length > 0 && 
                    <>
                        <br />
                        <DataTable
                            data={data.users}
                            columns={[
                                {
                                    name: 'Логин',
                                    center: true,
                                    selector: ({login}) => login
                                },
                                {
                                    name: "Серверный логин",
                                    center: true,
                                    selector: ({serverLogin}) => serverLogin
                                },
                                {
                                    name: 'Онлайн сегодня',
                                    center: true,
                                    selector: ({todayOnline}) => todayOnline
                                },
                                {
                                    name: "Форум",
                                    center: true,
                                    cell: ({forumId}) => <a target="_blank" href={`${config.forumURL}/members/${forumId}`} className="href"><Eye /></a>
                                }
                            ]}
                        /> 
                    </>     
                    }
                </div>
                <br />
                <div className="shadow-lg p-25 shadow-showcase">
                    <h3><b>Команда проекта [{data.employees.length}]</b></h3>
                    
                    {data.employees.length > 0 && 
                    <>
                        <br />
                        <DataTable
                            data={data.employees}
                            columns={[
                                {
                                    name: 'Логин',
                                    center: true,
                                    selector: ({login}) => login
                                },
                                {
                                    name: "Серверный логин",
                                    center: true,
                                    selector: ({serverLogin}) => serverLogin
                                },
                                {
                                    name: 'Позиция',
                                    center: true,
                                    selector: ({accessLevel}) => accessLevelsEmployee[accessLevel]
                                },
                                {
                                    name: 'Онлайн сегодня',
                                    center: true,
                                    selector: ({todayOnline}) => todayOnline
                                },
                                {
                                    name: "Форум",
                                    center: true,
                                    cell: ({forumId}) => <a target="_blank" href={`${config.forumURL}/members/${forumId}`} className="href"><Eye /></a>
                                }
                            ]}
                        /> 
                    </>     
                    }
                </div>
            </div>
            }
        </div>
    )
}