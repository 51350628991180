import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import defaultPrepareHeaders from '../defaultPrepareHeaders';
import config from '../../config.json';
import { EmployeeAccessLevel } from "../../types/models";

export type OnlineInfo = {
    createdAt: string
    users: {
        login: string
        serverLogin: string
        todayOnline: string
        forumId: number
    }[]
    employees: {
        login: string
        serverLogin: string
        accessLevel: EmployeeAccessLevel
        todayOnline: string
        forumId: number
    }[]
};


const infoApi = createApi({
    reducerPath: 'info',
    baseQuery: fetchBaseQuery({
        prepareHeaders: defaultPrepareHeaders,
        baseUrl: `${config.mainURL}/info`
    }),
    tagTypes: [],
    endpoints: build => ({
        getOnlineInfo: build.query<OnlineInfo, void>({
            query: () => '/online'
        }),
        getStats: build.query<Record<'charactersCount' | 'charactersPendingCount' | 'usersCount' | 'onlineCount', number>, void>({
            query: () => '/stats'
        })
    })
});

export const {
    useGetOnlineInfoQuery,
    useGetStatsQuery
} = infoApi;

export default infoApi;