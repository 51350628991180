import { Check, Clock, Info, Users } from "react-feather"
import { useGetStatsQuery } from "../../../store/api/infoApi"
import { useEffect } from "react";

export const InfoPageStatsBlock = () => {

    const {isSuccess, data, refetch} = useGetStatsQuery();

    useEffect(() => {
        let interval: any;
        if(isSuccess)
        {
            let blocked = false;
            interval = setInterval(() => {
                if(blocked) return;
                blocked = true;

                refetch().unwrap()
                    .then(() => (blocked = false))
                    .catch(() => (blocked = false));
            }, 2000);
        }
        return () => {
            if(interval) clearInterval(interval);
        }
    }, [isSuccess])


    return (
        <>
        <div className="col-sm-3 col-xl-3 col-lg-3">
            <div className="card o-hidden">
                <div className="bg-primary b-r-4 card-body">
                    <div className="d-flex static-top-widget">
                        <div className="align-self-center text-center">
                            <Users />
                        </div>
                        <div className="flex-grow-1">
                            <span className="m-0">{'Всего пользователей'}</span>
                            <h4 className="mb-0 counter">
                                {isSuccess ? data.usersCount : 'Загрузка...'}
                            </h4>
                            <Users className="icon-bg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-3 col-xl-3 col-lg-3">
            <div className="card o-hidden">
                <div className="bg-primary b-r-4 card-body">
                    <div className="d-flex static-top-widget">
                        <div className="align-self-center text-center">
                            <Check />
                        </div>
                        <div className="flex-grow-1">
                            <span className="m-0">{'Всего персонажей'}</span>
                            <h4 className="mb-0 counter">
                                {isSuccess ? data.charactersCount : 'Загрузка...'}
                            </h4>
                            <Check className="icon-bg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-3 col-xl-3 col-lg-3">
            <div className="card o-hidden">
                <div className="bg-primary b-r-4 card-body">
                    <div className="d-flex static-top-widget">
                        <div className="align-self-center text-center">
                            <Clock />
                        </div>
                        <div className="flex-grow-1">
                            <span className="m-0">{'Ожидают одобрения'}</span>
                            <h4 className="mb-0 counter">
                            {isSuccess ? data.charactersPendingCount : 'Загрузка...'}
                            </h4>
                            <Clock className="icon-bg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-3 col-xl-3 col-lg-3">
            <div className="card o-hidden">
                <div className="bg-primary b-r-4 card-body">
                    <div className="d-flex static-top-widget">
                        <div className="align-self-center text-center">
                            <Info/>
                        </div>
                        <div className="flex-grow-1">
                            <span className="m-0">{'Онлайн'}</span>
                            <h4 className="mb-0 counter">
                                {isSuccess ? data.onlineCount : 'Загрузка...'}
                            </h4>
                            <Info className="icon-bg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}