import { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { useForm } from "react-hook-form";
import axios from 'axios';
import config from '../config.json';
import { handlerApiErrors } from "../vendor/handlerApiErrors";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks/redux";
import { authUser } from "../store/slices/userSlice";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
// import { APP_URL as mainURL } from process.env

type FormValues = {
    email_or_login?: string
    email?: string
    login?: string
    password: string
    twofa_code?: string
    remember: boolean 
    [key: string]: string | boolean | undefined
}

export const LoginPage = () => {
    const patternsUser = {
        login: /^[\w ]+$/,
        email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    };
    const [serverError, setServerError] = useState<string | null>(null);
    const [disabledBtn, setBtn] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        register,
        formState: {errors},
        handleSubmit
    } = useForm<FormValues>({
        mode: 'onBlur'
    });
    async function formAuth(data: FormValues)
    {
        if(data.email_or_login)
            data.email_or_login = data.email_or_login.trim();
        for(const pattern in patternsUser)
        {
            if(patternsUser[pattern as 'login' | 'email'].test(data?.email_or_login ?? ''))
            {
                data[pattern as 'login' | 'email'] = data.email_or_login;
                delete data.email_or_login;
            }
        }
        if(data.email_or_login) {data.login = data.email_or_login; delete data.email_or_login};
    
        dispatch(authUser(data)).unwrap()
            .then(() => toast.success("Вы успешно авторизовались"))
            .catch(({error}) => toast.error(error));
        
    }
    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h4>Авторизация</h4>
                                                    <h6>Чтобы войти в аккаунт, введите свой логин и пароль.</h6>
                                                    {(serverError && <span className="txt-danger">{serverError}</span>)}
                                                </div>
                                                <form className="theme-form needs-validation" onSubmit={handleSubmit(formAuth)}>
                                                    <div className="form-group form-row">
                                                        <label className="col-form-label pt-0">Логин/E-Mail</label>
                                                        <input className="form-control" type="text" {...register('email_or_login', {required: "Введите E-Mail либо логин"})} />
                                                        {errors?.email_or_login && <span>{errors?.email_or_login?.message || 'email'}</span>}
                                                    </div>
                                                    <div className="form-group form-row">
                                                        <label className="col-form-label">Пароль</label>
                                                        <input className="form-control" {...register('password', {required: "Введите пароль"})} type="password" />
                                                        {errors?.password && <span>{errors?.password?.message || 'Password'}</span>}
                                                    </div>
                                                    {/* <div className="checkbox p-0">
                                                        <input {...register('remember')} id="checkbox1" type="checkbox" />
                                                        <label htmlFor="checkbox1">Запомнить меня</label>
                                                    </div> */}
                                                    <div className="form-group form-row mt-3 mb-0 d-grid">
                                                        <button disabled={disabledBtn} className="btn btn-primary" type="submit">Авторизация</button>
                                                    </div>
                                                    <br />
                                                    <p className="text-center">Нет аккаунта? Зарегистрируйтесь! <br /><Link to="/register">Зарегистрироваться</Link></p>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}