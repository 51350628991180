

export type AnswerRegistrationQuestionModel = {
    id: number
    questionId: number
    text: string
}

export type RegistrationQuestionModel = {
    id: number
    text: string
    answers: Array<AnswerRegistrationQuestionModel>
}

export enum EmployeeAccessLevel {
    RETIRED = 0,
    SUPPORT = 1,
    GAME_MASTER = 2,
    ADMIN = 3,
    PROJECT_MANAGER = 4,
    DEVELOPER = 5
}

export type UserModel = {
    id: number
    login: string
    email: string
    createdAt: string
}

export enum CharacterStatus {
    PENDING = 0,
    APPROVED = 1,
    REJECTED = 2,
    KILLED = 3,
    ARCHIVED = 4
}

export enum CharacterRequestedPerk
{
    Axe = 0,
    Blunt,
    SmallBlunt,
    LongBlade,
    SmallBlade,
    Spear,
    Maintenance,
    Aiming,
    Reloading,
    Woodwork,
    Cooking,
    Farming,
    Doctor,
    Electricity,
    MetalWelding,
    Mechanics,
    Tailoring,
    Fishing,
    Trapping,
    PlantScavenging,
    Fitness,
    Strength,
    Sprinting,
    Lightfoot,
    Nimble,
    Sneak
}

export type CharacterModel<T extends 'list' | 'get'> = T extends 'list'
?
{
    id: string
    name: string
    status: CharacterStatus
    gameHours: number
    createdAt: string
}
:
{
    id: string
    name: string
    status: CharacterStatus
    gameHours: number
    createdAt: string
    personality: string
    appearance: string
    notableSkills: string
    background: string
    answersCreateQuestions: {
        answerText: string
        questionText: string
    }[]
    comments: {
        text: string
        author: string
    }[]
    authInfo: Record<'login' | 'password', string> | null
}

export type CreateCharacterQuestionModel = {
    id: string
    text: string
}