import { useForm } from "react-hook-form"
import { registerUser } from "../../../store/slices/userSlice"
import { useAppDispatch } from "../../../hooks/redux"
import { toast } from "react-toastify"
import { handlerApiErrors } from "../../../vendor/handlerApiErrors"

type FormValues = {
    login: string
    email: string
    password: string
    token: string
}

export const RegisterFormCreateAccount = ({token}: {token: string}) => {

    const dispatch = useAppDispatch();

    const {
        register,
        formState: {errors},
        setValue,
        handleSubmit
    } = useForm<FormValues>()

    const createAccount = async (data: FormValues) => {

        data.token = token;

        dispatch(registerUser(data)).unwrap()
            .then(() => toast.success("Аккаунт успешно создан"))
            .catch(({error}) => toast.error(error));
    }
    
    return (
        <form className="theme-form needs-validation" onSubmit={handleSubmit(createAccount)}>
            <div className="form-group form-row">
                <label className="col-form-label pt-0">Логин</label>
                <input className="form-control" type="text" {...register('login', {required: true})} />
            </div>
            <div className="form-group form-row">
                <label className="col-form-label pt-0">E-Mail</label>
                <input className="form-control" type="text" {...register('email', {required: true})} />
            </div>
            <div className="form-group form-row">
                <label className="col-form-label pt-0">Пароль</label>
                <input className="form-control" type="text" {...register('password', {required: true})} />
            </div>
            <div className="d-grid">
                <button className="btn btn-primary">Регистрация</button>
            </div>
            
        </form>
    )
}