import React, { Fragment } from 'react';
import sad from '../../assets/images/other-images/sad.png';
import { Link } from 'react-router-dom';

export const Error404 = ({message}: {message?: string}) => {
    document.title = '404';
    return (
        <Fragment>
            <div className="page-wrapper">
                {/* <!-- error-404 start--> */}
                <div className="error-wrapper">
                    <div className="container"><img className="img-100" src={sad} alt="" />
                        <div className="error-heading">
                            <h2 className="headline font-danger">404</h2>
                        </div>
                        <div className="col-md-8 offset-md-2">
                            <p className="sub-content">{message ? message : 'Такой страницы не существует'}</p>
                        </div>
                        <div><Link to={'/'} className="btn btn-danger-gradien">Главная страница</Link></div>
                    </div>
                </div>
                {/* <!-- error-404 end--> */}
            </div>
        </Fragment>
    );
};