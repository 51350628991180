import React, { Fragment, useState } from 'react';
import man from '../../../assets/images/dashboard/user.png';
import { Settings, LogOut, LogIn, User } from 'react-feather';
import { useAuth } from '../../../hooks/useAuth';
import { Link, useNavigate } from 'react-router-dom';
import userAvatar from '../../../assets/images/user-logo.jpeg';
import { useDispatch } from 'react-redux';
import { logout } from '../../../store/slices/userSlice';

const UserMenu = () => {

    const dispatch = useDispatch();
    const logOut = () => {
        dispatch(logout());
        // deleteJwt();
        // setUser(null);
    }
    const [view, setView] = useState(false);
    return (
        <Fragment>
            <li style={{cursor: 'pointer'}}>
                <div className="d-flex align-items-center" onClick={() => setView(!view)}>
                    <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={userAvatar} alt="header-user" />
                    <div className="dotted-animation">
                        {/* <span className="animate-circle"></span> */}
                        {/* <span className="main-circle"></span> */}
                    </div>
                </div>
                <ul className="profile-dropdown onhover-show-div p-20" style={view ? {opacity: '1', visibility: 'visible', transform: 'translateY(0px)'} : {}}>
                    <li><Link to="/profile"><User/>Профиль</Link></li>
                    <li><a href="#" onClick={() => logOut()}><LogOut /> Выход</a></li>
                </ul>
            </li>
        </Fragment>
    );
};


export default UserMenu;