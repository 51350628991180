import DataTable from "react-data-table-component";
import { Check, Clock, Eye, Info, List, Users } from "react-feather"
import Breadcrumb from "../components/common/breadcrumb";
import { OnlineInfoCard } from "../components/pages/home/onlineInfo";
import { InfoPageStatsBlock } from "../components/pages/home/stats";

export const HomePage = () => {

    return (
        <>
            <br />
            <div className="container-fluid">
                <div className="row">
                    <InfoPageStatsBlock/>
                    <div className="col-sm-12">
                        <OnlineInfoCard/>
                    </div>
                </div>
            </div>
        </>
    )
}