
export const msGetItem = (name: string): any => {
    const expired = localStorage.getItem(`ms_expired_${name}`) as number | null;
    if(expired !== null)
    {
        if((Number(new Date()) / 1000) > expired) 
        {
            localStorage.removeItem(`ms_expired_${name}`);
            localStorage.removeItem(`ms_item_${name}`);
            return null;
        }
        else 
        {
            let item = localStorage.getItem(`ms_item_${name}`) as string;
            try
            {
                return JSON.parse(item);
            }
            catch(e)
            {
                return item;
            }
        }
    }
    else return null;
}

export const msSetItem = (name: string, item: any, expired: number): void => {
    let resultItem = null;
    if(Array.isArray(item) || typeof item == 'object')
        resultItem = JSON.stringify(item);
    else resultItem = item;

    localStorage.setItem(`ms_expired_${name}`, String(expired));
    localStorage.setItem(`ms_item_${name}`, resultItem);
}

export const msDeleteItem = (name: string) => {
    localStorage.removeItem(`ms_expired_${name}`);
    localStorage.removeItem(`ms_item_${name}`);
}

export const msContainsItem = (name: string): boolean => {
    if(`ms_item_${name}` in localStorage)
    {
        const expired = localStorage.getItem(`ms_expired_${name}`) as number | null;
        if(expired !== null)
        {
            if((Number(new Date()) / 1000) > expired) 
            {
                localStorage.removeItem(`ms_expired_${name}`);
                localStorage.removeItem(`ms_item_${name}`);
                return false;
            }
            else return true;
        }
        else return false;
    }
    else return false;
}

export const msDeleteCategoryItems = (string: string): void => {
    for(const key in localStorage)
        if(key.includes(`ms_item_${string}`) || key.includes(`ms_expired_${string}`)) 
            localStorage.removeItem(key);

}
