import { useState } from "react";

export const MainHeader = () => {

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(s => !s);

    return (
        <nav className="navbar navbar-expand-lg navbar-dark" style={{marginTop: '10px'}}>
            <div className="container">
                <button className="navbar-toggler" type="button" onClick={toggle}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
                    <ul className="navbar-nav mx-auto">
                        <li className="nav-item">
                            <a className="nav-link" href="https://forum.troubled-time.ru/" target="_blank">Форум</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://forum.troubled-time.ru/forums/pravila.4/" target="_blank">Правила</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://forum.troubled-time.ru/forums/igrovoj-lor.6/" target="_blank" className="nav-link">История мира</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}