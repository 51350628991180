import React, { Fragment, useState, useEffect } from 'react';
import Header from './common/header-component/header';
import Sidebar from './common/sidebar-component/sidebar';
// import RightSidebar from './common/right-sidebar';
// import Footer from './common/footer';
// import ThemeCustomizer from './common/theme-customizer'
import { ToastContainer } from 'react-toastify';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { MenuItem, getMenu } from './common/sidebar-component/menu';

export type MainMenuState = {
    mainmenu: Array<MenuItem>
}

const AppLayout = () => {
    
    const { user } = useAuth();
    let startedMainMenu = getMenu();
    const [mainmenu, setMainMenu] = useState<MainMenuState>({mainmenu: startedMainMenu});
    const [updatedActive, setUpdatedActive] = useState(false);
    
    useEffect(() => {
        setMainMenu({mainmenu: getMenu()});
        startedMainMenu = mainmenu.mainmenu;

        if(!updatedActive)
        {
            startedMainMenu.forEach(a => {
                if(a.path == window.location.pathname)
                {
                    a.active = true;
                    return false;
                }
                if(!a.children) return false;
                a.children.forEach(b => {
                    if(b.path == window.location.pathname)
                    {
                        a.active = true;
                        return false;
                    }
                })
            });
            setUpdatedActive(true);
            setMainMenu({ mainmenu: startedMainMenu });
        }
        

    }, []);
    

    return (
        <Fragment>
            {/* <Loader /> */}
            <div className="page-wrapper">
                <div className="page-body-wrapper">
                    <Header mainmenu={mainmenu} setMainMenu={setMainMenu}/>
                    <Sidebar mainmenu={mainmenu} setMainMenu={setMainMenu}/>
                    {/* <RightSidebar /> */}
                    <div className="page-body">
                        <Outlet />
                    </div>
                    {/* <Footer />
                    <ThemeCustomizer /> */}
                </div>
            </div>
            
        </Fragment>
    );
}

export default AppLayout;